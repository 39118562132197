@import url('https://fonts.googleapis.com/css?family=Poppins&display=swap');

html,
body,
#root {
  height: 100%;
}

body {
  font-family: 'Poppins', sans-serif;
  background-color: #fcfcfc;
}

.chat-area, .home {
  margin-top: 60px;
}

.chat-area {
  padding: 20px;
  height: 400px;
  overflow-y: scroll;
}

.chat-area .chat-bubble {
  background-color: #bbdefb;
  padding: 20px;
  border-radius: 4px;
  max-width: 300px;
  word-wrap: break-word;
}

.chat-bubble .chat-time {
  font-size: 12px;
  color: #757575;
  text-align: right;
}

.chat-area .chat-bubble.current-user {
  margin-left: auto;
  background-color: #a5d6a7;
}

.form-control {
  width: 60%;
}

.form-control:focus {
  box-shadow: none;
}

.btn {
  margin-top: 10px;
}

.btn-submit {
  background-color: #2e7d32;
  color: white;
}

@media screen and (max-width: 767px) {
  .form-control {
    width: 100%;
  }

  .chat-area .chat-bubble {
    max-width: 250px;
  }
}

/*.makeStyles-action-3 {*/
/*  position: fixed;*/
/*  left: 0;*/
/*  bottom: 0;*/
/*  width: 100%;*/
/*  height: 3.5rem;*/
/*  background-color: white;*/
/*  color: white;*/
/*}*/

/*.makeStyles-messages-2 {*/
/*  margin-bottom: 3.5rem;*/
/*}*/

/*.MuiFormControl-root {*/
/*  padding: 5px !important;*/
/*  flex-direction: row !important;*/
/*}*/

/*.MuiInputBase-root {*/
/*  width: 100% !important;*/
/*}*/

/*.MuiButtonBase-root {*/
/*  margin: 5px !important;*/
/*}*/